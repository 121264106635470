import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  update: any = false;

  constructor(
    private updates: SwUpdate
  ) {
    this.updates.available.subscribe(e => {
      this.update.activateUpdate().then(() => document.location.reload());
    });
  }
}
