import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalOverlayRef } from './../services/modal/modal-overlay-ref';

@Component({
  selector: 'app-pic-modal',
  templateUrl: './pic-modal.component.html',
  styleUrls: ['./pic-modal.component.scss']
})
export class PicModalComponent implements OnInit {

  title_en: string;
  title_ch: string;
  nickName: string;
  folder: string;
  picItem: any;

  constructor(
    private overlayRef: ModalOverlayRef,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  close() {
    this.overlayRef.close();
  }

  go(item: any) {
    this.overlayRef.close();
    this.router.navigate([`/pic/${item.id}`]);
  }

}
