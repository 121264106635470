import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HomeService } from '../services/home.service';
import * as $ from 'jquery';

interface PicType {
  type_ch: string;
  type_en: string;
  folder_image: string;
  tree: any[];
}

@Component({
  selector: 'app-pic-entrance',
  templateUrl: './pic-entrance.component.html',
  styleUrls: ['./pic-entrance.component.scss']
})
export class PicEntranceComponent implements OnInit {

  picList: any[];

  constructor(
    public homeSer: HomeService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    // const widthAll = $(window).width();
    // if (widthAll >= 812) {
    //   $('.loading-box').css('display','flex');
    //   $('.content').css('display','none');
    //   setTimeout(() => {
    //     $('.loading-box').addClass('init');
    //     $('.content').css('display','block');
    //     $('.main-title-mobile-init').addClass('anim-typewriter-mobile');
    //     $('.main-title-pc-init').addClass('anim-typewriter-pc');
    //   },2000);
    //   setTimeout(() => {
    //     $('.loading-box').css('display','none');
    //   },2500);
    // }
    window.scroll(0, 0);
    this.picList = this.homeSer.picList.info;
  }

  go(item: any) {
    this.router.navigate([`/pic/${item.id}`]);
  }

  goBack() {
    this.router.navigate([`/home`]);
  }

}
