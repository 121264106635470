import { Component, OnInit, Inject } from '@angular/core';
import { HomeService } from '../services/home.service';
import { ModalService } from '../services/modal.service';
import { PicModalComponent } from '../pic-modal/pic-modal.component';
import * as $ from 'jquery';

import { Router } from '@angular/router';

interface PicType {
  type_ch: string;
  type_en: string;
  folder_image: string;
  tree: any[];
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private _picGroup = [];
  videoLink: string;

  constructor(
    public homeSer: HomeService,
    private modalSer: ModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    const widthAll = $(window).width();
    this.loadInit();
    window.scroll(0, 0);
    if (widthAll <= 812) {
      this.videoLink = 'about.jpg';
    } else {
      this.videoLink = 'slider.mp4';
    }
    $('.main-title-click').click(() => {
      $('html,body').animate({scrollTop: $('#tag_content').offset().top}, 800);
    });
  }

  loadInit() {
    const image = new Image();
    image.onload = function () {
      $('.loading-box').css('background', 'none');
      $('body').css('overflow', 'hidden');
      setTimeout(() => {
        $('body').css('overflow', 'auto');
        $('.loading-box').css('display', 'none');
      }, 2600);
      setTimeout(() => {
        $('.main-title-mobile-init').addClass('anim-typewriter-mobile');
        $('.main-title-pc-init').addClass('anim-typewriter-pc');
      }, 1000);
      console.log('init')
    };
    image.src = './../../assets/image/loading.gif';
  }

  get picGroup() {
    if (this._picGroup && this._picGroup.length) {
      return this._picGroup;
    }
    if (this.homeSer.picList.info && this.homeSer.picList.info.length) {
      const tmp = [...this.homeSer.picList.info];
      while (tmp.length) {
        this._picGroup.push(tmp.splice(0, 3));
      }
    }
    return this._picGroup;
  }

  picFun(item: PicType) {
    this.modalSer.open(PicModalComponent, {
      hasBackdrop: true,
    }, component => {
      component.title_en = item.type_en;
      component.title_ch = item.type_ch;
      component.folder = item.folder_image;
      component.picItem = item;
    });
  }

  goTop() {
    $('html,body').animate({scrollTop: $('#home').offset().top}, 800);
  }

  path(link: string) {
    this.router.navigate([`${link}`]);
  }
}