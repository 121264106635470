import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PicComponent } from './pic/pic.component';
import { VideoComponent } from './video/video.component';
import { PicEntranceComponent } from './pic-entrance/pic-entrance.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'pic-entrance',
    component: PicEntranceComponent
  },
  {
    path: 'pic/:id',
    component: PicComponent
  },
  {
    path: 'video',
    component: VideoComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
