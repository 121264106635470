import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class BaseObservableComponent implements OnDestroy {

  sub = new Subscription();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
