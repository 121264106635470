import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './shared/component/material.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PostCreateComponent } from './posts/post-create/post-create.component';
import { PostListComponent } from './posts/post-list/post-list.component';
import { DialogTestComponent } from './posts/dialog-test/dialog-test.component';
import { DialogUniqueComponent } from './posts/dialog-unique/dialog-unique.component';
import { HomeComponent } from './home/home.component';
import { PicComponent } from './pic/pic.component';
import { PicModalComponent } from './pic-modal/pic-modal.component';
import { VideoComponent } from './video/video.component';
import { VideoInfoComponent } from './video-info/video-info.component';
import { PicEntranceComponent } from './pic-entrance/pic-entrance.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    PostCreateComponent,
    PostListComponent,
    DialogTestComponent,
    DialogUniqueComponent,
    HomeComponent,
    PicComponent,
    PicModalComponent,
    VideoComponent,
    VideoInfoComponent,
    PicEntranceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogTestComponent,
    DialogUniqueComponent,
    PicModalComponent,
    VideoInfoComponent
  ],
})
export class AppModule {}
