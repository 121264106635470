import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { ModalService } from '../services/modal.service';
import { HomeService } from '../services/home.service';
import { VideoInfoComponent } from './../video-info/video-info.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  videoList: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public homeSer: HomeService,
    private modalSer: ModalService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    // const widthAll = $(window).width();
    // if (widthAll >= 812) {
    //   $('.loading-box').css('display','flex');
    //   $('.content').css('display','none');
    //   setTimeout(() => {
    //     $('.loading-box').addClass('init');
    //     $('.content').css('display','block');
    //     $('.main-title-mobile-init').addClass('anim-typewriter-mobile');
    //     $('.main-title-pc-init').addClass('anim-typewriter-pc');
    //   },2000);
    //   setTimeout(() => {
    //     $('.loading-box').css('display','none');
    //   },2500);
    // }

    window.scroll(0, 0);
    this.videoList = this.homeSer.videoList.info;
  }

  goVideo(item) {
    this.modalSer.open(VideoInfoComponent, {
      hasBackdrop: true,
    }, component => {
      component.title = item.video_name;
      component.videoItem = item;
    });
  }

  goBack() {
    this.router.navigate([`/home`]);
  }

}
