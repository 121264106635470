import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalOverlayRef } from './../services/modal/modal-overlay-ref';

@Component({
  selector: 'app-video-info',
  templateUrl: './video-info.component.html',
  styleUrls: ['./video-info.component.scss']
})
export class VideoInfoComponent implements OnInit {

  title: string;
  videoItem: any;
  serviceUrl: SafeResourceUrl;

  constructor(
    private overlayRef: ModalOverlayRef,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.serviceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoItem.video_url);
  }

  close() {
    this.overlayRef.close();
  }

}
