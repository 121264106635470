import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HomeService } from '../services/home.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-pic',
  templateUrl: './pic.component.html',
  styleUrls: ['./pic.component.scss']
})
export class PicComponent implements OnInit {

  title: string;
  id: number;
  seletePic: any;

  constructor(
    public homeSer: HomeService,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    // const widthAll = $(window).width();
    // if (widthAll >= 812) {
    //   $('.loading-box').css('display','flex');
    //   $('.content').css('display','none');
    //   setTimeout(() => {
    //     $('.loading-box').addClass('init');
    //     $('.content').css('display','block');
    //     $('.main-title-mobile-init').addClass('anim-typewriter-mobile');
    //     $('.main-title-pc-init').addClass('anim-typewriter-pc');
    //   },2000);
    //   setTimeout(() => {
    //     $('.loading-box').css('display','none');
    //   },2500);
    // }
    window.scroll(0, 0);
    this.id = this.activeRoute.snapshot.params.id;
    this.getPicInfo(this.id);
  }

  getPicInfo(id) {
    this.homeSer.picList.info.forEach((itemList) => {
      itemList.tree.forEach(item => {
        if (item.id === id) {
          this.seletePic = item;
        }
      });
    });
  }

  goBack() {
    this.location.back();
  }

}
